import { getAuth } from "firebase/auth";
import { doc, getDoc, increment, setDoc } from "firebase/firestore";
import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import { db } from "../Config/Config";
import "./css/Pdf.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [userId, setuserId] = useState(null);
  const [video_progress, setvideo_progress] = useState(0);
  const [numPages, setNumPages] = useState(1);

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent right-click context menu
  };

  const handlePrint = () => {
    return false; // Prevent printing
  };

  useEffect(() => {
    // Update scale when screen width changes
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setScale(0.35);
      } else if (window.innerWidth <= 1024) {
        setScale(0.8);
      } else {
        setScale(1);
      }
    };

    handleResize(); // Initialize scale based on initial screen width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNextPage = (pageNo) => {
    setCurrentPage(pageNo);
    window.scrollTo(0, 0); // Scroll to the top
    const per = (pageNo / numPages) * 100;
    const u_per = ((video_progress + Math.floor(per)) / 200) * 100;
    uploadPage(pageNo, u_per, Math.floor(per));
  };

  const handlePreviousPage = (pageNo) => {
    if (currentPage > 1) {
      setCurrentPage(pageNo);
      window.scrollTo(0, 0); // Scroll to the top
      const per = (pageNo / numPages) * 100;
      const u_per = ((video_progress + Math.floor(per)) / 200) * 100;
      uploadPage(pageNo, u_per, Math.floor(per));
    } 
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setuserId(user.uid);
    pageFetch(user.uid);
  }, []);

  const pageFetch = async (uid) => {
    const docRef = doc(db, "play_position", uid, "positions", location.state.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setCurrentPage(docSnap.data().pdf_index || 1);
      setvideo_progress(parseInt(docSnap.data().video_progress));
      lastOpened(uid);
    }
  };

  const uploadTime = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    await setDoc(
      doc(db, "play_position", user.uid, "positions", location.state.id),
      {
        total_pdf_time_spend: increment(15),
      },
      { merge: true }
    );
  };

  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      uploadTime();
    }, 15000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const uploadPage = async (no, progress, pdf) => {
    await setDoc(
      doc(db, "play_position", userId, "positions", location.state.id),
      {
        pdf_index: no,
        progress: Math.floor(progress),
        pdf_progress: pdf,
      },
      { merge: true }
    );
  };

  const lastOpened = async (uid) => {
    const date = new Date();
    await setDoc(
      doc(db, "play_position", uid, "positions", location.state.id),
      {
        pdf_last_opened_time: date,
        chapter_last_opened_time: date,
        thumbnail: location.state.data.thumbnail,
        chapter_name: location.state.data.chapter_name,
      },
      { merge: true }
    );
  };

  return (
    <div className="pdf-container">
      <div
        className="min-h-screen bg-gray-100 py-12"
        onContextMenu={handleContextMenu}
        onBeforePrint={handlePrint}
      >
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <div className="w-full bg-white rounded-lg shadow-lg p-4">
              <Document
                file={location.state.url}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <div className="pdf-container">
                  <div className="pdf-overlay"></div>
                  <div className="pdf-page-container">
                    <Page
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      key={`page_${currentPage}`}
                      pageNumber={currentPage}
                      width={740 * scale}
                      className="pdf-page"
                    />
                  </div>
                </div>

                <div className="flex justify-center space-x-1 dark:text-gray-100">
                  {numPages > 10 && (
                    <button
                      title="previous"
                      type="button"
                      className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow-md dark:bg-blue-500 dark:border-gray-400"
                      onClick={() => {
                        const prevPage = Math.max(currentPage - 10, 1);
                        handlePreviousPage(prevPage);
                      }}
                      disabled={currentPage <= 1}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4"
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                  )}
                  {Array.from(
                    { length: Math.min(10, numPages - currentPage + 1) },
                    (_, index) => (
                      <button
                        key={`page_button_${index + currentPage}`}
                        type="button"
                        className={`inline-flex items-center justify-center w-8 h-8 text-sm border rounded shadow-md ${
                          currentPage === index + currentPage
                            ? "bg-blue-500 text-white"
                            : "bg-white text-black"
                        }`}
                        onClick={() => handleNextPage(index + currentPage)}
                      >
                        {index + currentPage}
                      </button>
                    )
                  )}
                  {numPages > 10 && currentPage + 10 <= numPages && (
                    <button
                      title="next"
                      type="button"
                      className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow-md dark:bg-blue-500 dark:border-blue-500"
                      onClick={() => {
                        handleNextPage(currentPage + 10);
                      }}
                      disabled={currentPage >= numPages - 10}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  )}
                </div>
              </Document>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
